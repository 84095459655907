/* You can add global styles to this file, and also import other style files */
@import '~jsoneditor/dist/jsoneditor.min.css';

div.jsoneditor-menu > button {
  background-image: url('https://jsoneditoronline.org/img/jsoneditor-icons.svg');
}
//textarea.jsoneditor-text{min-height:550px;}
.jsoneditor {
  height: 600px;
}
//roles-and-permissions
table.table-custom-hook {
  height: 65vh;
  display: block;
  position: relative;
  overflow: scroll;
}

.filterBox {
  background: #cccaca;
  padding: 1px 10px 60px 10px;
  margin-top: 7px;
  border-radius: 3px;
}

.filterBoxGeneralFilter {
  background: #cccaca;
  padding: 10px 30px 0px 10px;
  margin-top: 7px;
  border-radius: 3px;
}
.control-label.required:after {
  color: #d00;
  content: '*';
}
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}

.active-dot, .inactive-dot, .live-dot, .excluded-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.active-dot {
  background-color: #1fb93c;
}
.inactive-dot {
  background-color: #bbb;
}
.live-dot {
  background-color: #1fb93c;
  margin-left: 5px;
}
.excluded-dot {
  background-color: red;
}

.reportDate {
  float: right;
}
a {
  cursor: pointer;
}
.p-dialog-titlebar {
  font-size: 10px;
}
.tbd {
  font-weight: 600;
}

// validation errors
.p-error {
  font-size: 10px;
}

.float-right {
  float: right;
}

.upload-buttons #seo-upload .p-fileupload.p-fileupload-basic.p-component.ng-star-inserted .p-messages.p-component {
  //position: absolute !important;
  //top: -16% !important;
  //right: -71% !important;
  //width: 260px !important;
}

.p-picklist-list {
  min-height: 355px !important;
  max-height: 355px !important;
}

div.jsoneditor-menu > button {
  background-image: url("~jsoneditor/dist/img/jsoneditor-icons.svg") !important;
}

.layout-config {
  .field-radiobutton {
    label {
      cursor: pointer;
    }
  }
}

// identical height of card elements which are inside grid cols
.p-card-aligned-height {
  height: 100%;
}

.overlay-panel-img {
  min-width: 50px;
}

// On update from primeng 14 to 15 a chevronrighticon element (>) is added inside p-breadcrumb which is not compatible with our layout (/)
.p-breadcrumb-chevron {
  chevronrighticon {
    display: none;
  }
}

.p-component-overlay {
  z-index: 2900;
}

.p-toast {
  z-index: 3000!important;
}
